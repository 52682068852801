#nonFavs {
  overflow: auto;
  height: 300px;
}

#titleUrl {
  padding-left: 20px;
}

.noPadding {
  --padding-top: 0;
  --padding-bottom: 0;
  --margin-top: 0;
  --margin-bottom: 0;
}
