ion-content ion-toolbar {
  --background: transparent;
}

.minWidth {
  min-width: 150px;
}
.rdt_Pagination {
  justify-content: flex-start !important;
}
